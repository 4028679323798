// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advanced-forex-js": () => import("./../../../src/pages/advanced-forex.js" /* webpackChunkName: "component---src-pages-advanced-forex-js" */),
  "component---src-pages-algorithms-js": () => import("./../../../src/pages/algorithms.js" /* webpackChunkName: "component---src-pages-algorithms-js" */),
  "component---src-pages-associate-trader-programme-js": () => import("./../../../src/pages/associate-trader-programme.js" /* webpackChunkName: "component---src-pages-associate-trader-programme-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-crypto-like-a-pro-js": () => import("./../../../src/pages/crypto-like-a-pro.js" /* webpackChunkName: "component---src-pages-crypto-like-a-pro-js" */),
  "component---src-pages-crypto-like-a-pro-system-js": () => import("./../../../src/pages/crypto-like-a-pro-system.js" /* webpackChunkName: "component---src-pages-crypto-like-a-pro-system-js" */),
  "component---src-pages-elite-trader-programme-js": () => import("./../../../src/pages/elite-trader-programme.js" /* webpackChunkName: "component---src-pages-elite-trader-programme-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indicators-js": () => import("./../../../src/pages/indicators.js" /* webpackChunkName: "component---src-pages-indicators-js" */),
  "component---src-pages-intro-forex-course-js": () => import("./../../../src/pages/intro-forex-course.js" /* webpackChunkName: "component---src-pages-intro-forex-course-js" */),
  "component---src-pages-options-js": () => import("./../../../src/pages/options.js" /* webpackChunkName: "component---src-pages-options-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refund-js": () => import("./../../../src/pages/refund.js" /* webpackChunkName: "component---src-pages-refund-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

